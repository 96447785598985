
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NOTICE_SEARCH_TYPE, NOTICE_TYPE, USER_LEVEL } from '@/enum/enums';
import { Notice } from '@/interface/interface';
import FloatingButton from '@/components/floating-btn.vue';
@Component({
  components: {
    SubmitButton: FloatingButton
  }
})
export default class MyPageBoard extends Vue {
  @Prop() selectedId?: string;
  @Prop() isNew?: boolean;
  private page = 1;
  private totalPages = 0;
  private totalDocs = 0;
  private cols = 6;
  private startDate?: Date;
  private endDate?: Date;
  private search: NOTICE_SEARCH_TYPE | null = null;
  private searchValue: string | null = null;
  private docs: Notice[] = new Array(10).fill(this.dummyData);
  private noticeTypeOptions: { text: string; value: NOTICE_TYPE }[] = [];

  async created() {
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  private get checkQuery() {
    const rtnQuery: {
      page: number, search: NOTICE_SEARCH_TYPE | null, searchValue: string | null,
    } = {
      page: 1,
      search: null,
      searchValue: null,
    };
    const { query } = this.$route;
    const { page, search, searchValue } = query;
    if (!isNaN(+page)) rtnQuery.page = +page;
    if (search && typeof search === 'string') rtnQuery.search = search as NOTICE_SEARCH_TYPE;
    if (searchValue && typeof search === 'string') rtnQuery.searchValue = searchValue as string;
    return rtnQuery;
  }

  focus(): HTMLTableElement | null {
    const { table } = this.$refs;
    if (table && this.isNav) {
      const { $el } = table as Vue;
      if ($el) {
        return $el as HTMLTableElement;
      }
    }
    return null;
  }

  write() {
    this.$router.push({ path: '/my-page/board/new/write' });
  }

  linkGen (page: string) {
    return `?page=${page}&search=${this.search || ''}&searchValue=${this.searchValue || ''}&isNav=true`;
  }

  find() {
    this.page = 1;
    this.$router.push({
      path: '/my-page/board',
      query: this.queries,
    });
  }

  searchTypeChange() {
    if (this.search !== NOTICE_SEARCH_TYPE.TYPE) {
      this.searchValue = '';
    }
  }

  async load() {
    const { page, search, searchValue } = this.checkQuery;
    this.page = page;
    this.search = search;
    this.searchValue = searchValue;
    const { data, result } = await this.axios({
      url: `/notice/list`,
      params: {
        page: this.page,
        search: this.search,
        searchValue: this.searchValue,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedId: this.isNew ? null : this.selectedId,
      }
    });
    if (result) {
      const { totalDocs, docs, totalPages, page, limit, next, prev } = data as { totalDocs: number, docs: Notice[], totalPages: number, page: number, limit: number, next: Notice | null, prev: Notice | null };
      this.totalPages = totalPages;
      this.totalDocs = totalDocs;
      this.docs = docs;
      const table = this.focus();
      this.$emit('ready', { docs, next, prev, page, totalPages, limit, table });
    }

    // MARK: 글 유형
    await this.loadNoticeTypes();
  }

  private async loadNoticeTypes () {
    const { data } = await this.axios.get<{ text: string; value: NOTICE_TYPE }[]>('/notice/types');
    this.noticeTypeOptions = data;
  }

  typeIcon(type: NOTICE_TYPE): string {
    const TYPE = type.toUpperCase();
    if (TYPE === NOTICE_TYPE.PANEL_INQUIRY) return 'question';
    if (TYPE === NOTICE_TYPE.ANNOUNCEMENT) return 'flag';
    if (TYPE === NOTICE_TYPE.ANSWER) return 'reply';
    if (TYPE === NOTICE_TYPE.SPECIFIC) return 'user';
    return '';
  }

  get searchTypeOptions() {
    /*

  TYPE = 'type', 유형
  TITLE = 'title', 제목
  CONTENT = 'content', 내용
  USER = 'user', 작성자

    * */
    return Object.entries(this.searchType).map(([key, value]) => {
      return {
        text: this.$t(`MY_PAGE.BOARD.SEARCH_TYPE.${ value.toUpperCase() }`),
        value,
      }
    });
  }

  get searchType() {
    return NOTICE_SEARCH_TYPE;
  }

  get noticeType() {
    return NOTICE_TYPE;
  }

  get queries() {
    return {
      page: this.page.toString(),
      search: this.search,
      searchValue: this.searchValue,
      timeStamp: new Date().getTime().toString()
    }
  }

  get dummyData() {
    const dummy: Notice = {
      index: 0,
      content: '',
      replaceContent: '',
      order: 0,
      read: false,
      readCount: 0,
      readDate: '',
      regDate: '',
      regUserId: '',
      sendUserId: [],
      title: '',
      type: NOTICE_TYPE.ANNOUNCEMENT,
      typeLabel: '',
      _id: '',
      commentCount: 0,
      commentActive: false,
      answered: false,
      targetGroup: [],
      uploadedFiles: [],
      uploadedFilesCount: 0,
      new: false,
      isLike: false,
      likeCount: 0,
    };
    return dummy;
  }

  get isNav(): boolean {
    const { isNav } = this.$route.query;
    return !!isNav;
  }

  get isBoard(): boolean {
    const { name } = this.$route;
    return name === 'myPageBoard';
  }

  get isAdmin() {
    const { GENERAL_ADMIN, SUPER_ADMIN } = USER_LEVEL
    return [GENERAL_ADMIN, SUPER_ADMIN].includes(this.getUserLevel)
  }
}
